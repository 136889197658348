







































































































































































































































































































































































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useContext,
  useRouter
} from '@nuxtjs/composition-api'
import {
  Hooper,
  Slide,
  Pagination as HooperPagination,
  Navigation as HooperNavigation
} from 'hooper'
import 'hooper/dist/hooper.css'
import JapanPostalCode from 'japan-postal-code'
import SearchInput from '~/components/search/Input.vue'
import { getDateString } from '~/libs/dayjs'
import ListEventsTop from '~/components/events/ListEventsTop.vue'
import NoteItem from '~/components/pickups/NoteItem.vue'
import AdFrame from '~/components/AdFrame.vue'
import ListNoteTop from '~/components/ListNoteTop.vue'
import YoutubeLive from '~/components/YoutubeLive.vue'
import CampaignsItem from '~/components/campaigns/item.vue'
import Meta from '~/assets/mixins/meta'
import LogoVookSvg from '~/assets/images/svg/logo_vook.svg?inline'
import IconCalendarSvg from '~/assets/images/svg/icon_calendar.svg?inline'

// MEMO: 現在使ってはいないが再利用するかもなので残してます。リニューアルタイミングで使わなくなる場合は、コンポーネントのファイルごと削除する。
// import TopNoteItem from '~/components/TopNoteItem'

import themeTrend from '~/assets/images/svg/theme_trend.svg?inline'
import themeCreativity from '~/assets/images/svg/theme_creativity.svg?inline'
import themeBusiness from '~/assets/images/svg/theme_business.svg?inline'
import themeCareer from '~/assets/images/svg/theme_career.svg?inline'
import themeSkill from '~/assets/images/svg/theme_skill.svg?inline'
import { convertImage, convertAssetImage } from '~/libs/imagepix'
import { campaigns } from '~/libs/campaigns'
import { hasMailStatus, getAge, getOccupationName } from '~/libs/utils'
import useCookies from '~/composables/useCookies'
import { date } from '~/libs/validation-custom-rules'

export default defineComponent({
  components: {
    SearchInput,
    AdFrame,
    ListNoteTop,
    ListEventsTop,
    NoteItem,
    YoutubeLive,
    Hooper,
    Slide,
    HooperPagination,
    HooperNavigation,
    LogoVookSvg,
    IconCalendarSvg,
    // TopNoteItem,
    CampaignsItem,
    themeTrend,
    themeCreativity,
    themeBusiness,
    themeCareer,
    themeSkill
  },
  mixins: [Meta],
  watchQuery: true,
  setup() {
    const { $axios, $auth } = useContext()
    const $cookies = useCookies()
    const $router = useRouter()

    const hooper = ref()
    const recommendations = ref<Note[]>([])
    const pickups = ref<Note[]>([])
    const notes = ref<Note[]>([])
    const noteSummaries = ref<Note[]>([])
    const careers = ref<careers.JobOffer[]>([])
    const movies = ref<Note[]>([])
    const trendNotes = ref<Note[]>([])
    const creativityNotes = ref<Note[]>([])
    const businessNotes = ref<Note[]>([])
    const careerNotes = ref<Note[]>([])
    const skillNotes = ref<Note[]>([])
    const trendMovie = ref<Note>()
    const creativityMovie = ref<Note>()
    const businessMovie = ref<Note>()
    const careerMovie = ref<Note>()
    const skillMovie = ref<Note>()

    // 特定のcookieがあった場合はリダイレクト
    if ($cookies.get('archivePath')) {
      const archivePath = $cookies.get('archivePath')
      $cookies.remove('archivePath')
      $router.push(archivePath)
    }
    if ($cookies.get('eventPath')) {
      const eventPath = $cookies.get('eventPath')
      $cookies.remove('eventPath')
      $router.push(eventPath)
    }

    const fetchRecommendations = async () => {
      try {
        const responseData = await $axios.$get('/top_page/recommendations')
        recommendations.value = responseData.slice(0, 6)
      } catch (error) {
        alert(error)
      }
    }

    const fetchPickups = async () => {
      try {
        const responseData = await $axios.$get('/top_pickups')
        pickups.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchNotes = async () => {
      try {
        const params = { per: 10 }
        const responseData = await $axios.$get('/v2/notes', { params })
        notes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchNoteSummaries = async () => {
      try {
        const responseData = await $axios.$get('/note_summaries')
        noteSummaries.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchCareers = async () => {
      try {
        const params = { per: 6 }
        const responseData = await $axios.$get('/careers/job_offers', {
          params
        })
        careers.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchMovies = async () => {
      try {
        const params = { per: 10, type: 'movie' }
        const responseData = await $axios.$get('/v2/notes', { params })
        movies.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchTrendNotes = async () => {
      try {
        const params = { per: 3, theme: 1 }
        const responseData = await $axios.$get('/v2/notes', { params })
        trendNotes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchCreativityNotes = async () => {
      try {
        const params = { per: 4, theme: 2 } // リリース時点では、まだ映像コンテンツがないため、記事を4件表示する
        const responseData = await $axios.$get('/v2/notes', { params })
        creativityNotes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchBusinessNotes = async () => {
      try {
        const params = { per: 3, theme: 3 }
        const responseData = await $axios.$get('/v2/notes', { params })
        businessNotes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchCareerNotes = async () => {
      try {
        const params = { per: 3, theme: 4 }
        const responseData = await $axios.$get('/v2/notes', { params })
        careerNotes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchSkillNotes = async () => {
      try {
        const params = { per: 4, theme: 5 } // リリース時点では、まだ映像コンテンツがないため、記事を4件表示する
        const responseData = await $axios.$get('/v2/notes', { params })
        skillNotes.value = responseData
      } catch (error) {
        alert(error)
      }
    }

    const fetchTrendMovie = async () => {
      try {
        const params = { per: 1, type: 'movie', theme: 1 }
        const responseData = await $axios.$get('/v2/notes', { params })
        trendMovie.value = responseData[0]
      } catch (error) {
        alert(error)
      }
    }

    const fetchCreativityMovie = async () => {
      try {
        const params = { per: 1, type: 'movie', theme: 2 }
        const responseData = await $axios.$get('/v2/notes', { params })
        creativityMovie.value = responseData[0]
      } catch (error) {
        alert(error)
      }
    }

    const fetchBusinessMovie = async () => {
      try {
        const params = { per: 1, type: 'movie', theme: 3 }
        const responseData = await $axios.$get('/v2/notes', { params })
        businessMovie.value = responseData[0]
      } catch (error) {
        alert(error)
      }
    }

    const fetchCareerMovie = async () => {
      try {
        const params = { per: 1, type: 'movie', theme: 4 }
        const responseData = await $axios.$get('/v2/notes', { params })
        careerMovie.value = responseData[0]
      } catch (error) {
        alert(error)
      }
    }

    const fetchSkillMovie = async () => {
      try {
        const params = { per: 1, type: 'movie', theme: 5 }
        const responseData = await $axios.$get('/v2/notes', { params })
        skillMovie.value = responseData[0]
      } catch (error) {
        alert(error)
      }
    }

    const sendKarte = async () => {
      if (!$auth.loggedIn) {
        return
      }

      try {
        const responseData = await $axios.$get('/events/entries')
        let _address = null
        let prefCd = null
        if ($auth.user?.postal_code) {
          JapanPostalCode.get($auth.user.postal_code as string, (address) => {
            prefCd = address.prefecture
            _address =
              address.prefecture +
              $auth.user?.city_address +
              $auth.user?.mansion_name
          })
        }
        // @ts-ignore
        krt('send', 'identify', {
          user_id: String($auth.user?.id),
          email: $auth.user?.email,
          subscription: hasMailStatus($auth.user?.mail_state),
          first_name: $auth.user?.first_name,
          last_name: $auth.user?.last_name,
          address: _address
        })

        const birthday = $auth.user?.birthdate
          ? new Date($auth.user.birthdate as Date)
          : null

        let _event = null
        if (responseData.length > 0) {
          _event = responseData.map((v: any) => v.event.id).join()
        }

        const clipsIds =
          ($auth.user as any)?.clipped_note_ids.length > 0
            ? ($auth.user as any)?.clipped_note_ids.join()
            : null

        const workExperience = $auth.user?.work_experience
          ? ($auth.user as any)?.work_experience.name
          : ''

        const productionWilling = $auth.user?.video_production_mind
          ? ($auth.user as any)?.video_production_mind.name
          : ''

        const jobType = $auth.user?.job_category
          ? ($auth.user as any).job_category.name
          : ''

        const videoCategories =
          ($auth.user as any)?.video_categories?.length > 0
            ? ($auth.user as any)?.video_categories.map(
                (category: any) => category.name
              )
            : ''

        // @ts-ignore
        krt('send', 'attribute', {
          signup_date: new Date($auth.user?.created_at as Date),
          withdrawal_date: null,
          birth_date: birthday,
          gender: $auth.user?.gender,
          occupation: getOccupationName(
            Number($auth.user?.occupation_id),
            $auth.user?.occupation_other
          ),
          member_status: $auth.user?.status,
          vook_career: $auth.user?.career_agreement,
          event: _event,
          questionnaire: null,
          vook_school: $auth.user?.school_status,
          age: getAge(birthday, new Date()),
          clip: clipsIds,
          pref_cd: prefCd,
          production_experience: workExperience,
          production_willing: productionWilling,
          job_type: jobType,
          production_category: videoCategories
        })
      } catch (error) {
        alert(error)
      }
    }

    const clickSlide = (event: any) => {
      if (hooper.value.isSlding) {
        event.preventDefault()
      }
    }

    // MEMO: 現在使ってはいないが再利用するかもなので残してます。リニューアルタイミングで使わなくなる場合は削除する。
    const noteDate = (note: any) => {
      if (note.published_at) {
        if (note.published_at < note.updated_at) {
          return getDateString(note.updated_at) + ' 更新'
        } else {
          return getDateString(note.published_at)
        }
      } else {
        return getDateString(note.updated_at)
      }
    }

    const premium = (note: Note) => {
      if (note.only_premium) {
        return true
      }

      if (note.movie?.permission === 'paid') {
        return true
      }
      return false
    }

    const campaignsTop = computed(() => {
      // SPでは4件表示。PCでは、スタイルで4件目を非
      return campaigns.slice(0, 4)
    })

    onMounted(async () => {
      await Promise.all([
        fetchRecommendations(),
        fetchPickups(),
        fetchNotes(),
        fetchMovies(),
        fetchNoteSummaries(),
        fetchCareers(),
        fetchTrendNotes(),
        fetchCreativityNotes(),
        fetchBusinessNotes(),
        fetchCareerNotes(),
        fetchSkillNotes(),
        fetchTrendMovie(),
        fetchCreativityMovie(),
        fetchBusinessMovie(),
        fetchCareerMovie(),
        fetchSkillMovie(),
        sendKarte()
      ])
    })

    return {
      meta: {},
      bodyAttrs: { class: 'header-fixed' },
      recommendations,
      pickups,
      notes,
      movies,
      noteSummaries,
      careers,
      campaignsTop,
      trendNotes,
      creativityNotes,
      businessNotes,
      careerNotes,
      skillNotes,
      trendMovie,
      creativityMovie,
      businessMovie,
      careerMovie,
      skillMovie,
      clickSlide,
      convertImage,
      convertAssetImage,
      getDateString,
      premium,
      date,
      hooperSettings: {
        centerMode: true,
        infiniteScroll: true,
        wheelControl: false,
        autoPlay: true,
        playSpeed: 4500,
        itemsToShow: 1,
        itemsToSlide: 1,
        breakpoints: {
          600: {
            itemsToShow: 1.3
          }
        }
      }
    }
  }
})
